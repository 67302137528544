import { APP_ENVIRONMENT, APP_ENVIRONMENT_DEV } from 'constants/app.constant'

const isDev = () => {
	if (APP_ENVIRONMENT === APP_ENVIRONMENT_DEV) {
		return true
	}
	return false
}

export default isDev
