import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dateLocales } from 'locales'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { STORE_LANG } from 'constants/app.constant'
import { setLang } from 'store/theme/themeSlice'
import { themeConfig } from 'configs/theme.config'

function useLocale() {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()
	const locale = useSelector((state) => state.theme.locale)
	const storedLocale = localStorage.getItem(STORE_LANG) || themeConfig.locale

	useEffect(() => {
		const formattedLang = storedLocale.replace(/-([a-z])/g, function (g) {
			return g[1].toUpperCase()
		})

		dispatch(setLang(formattedLang))

		if (formattedLang && formattedLang !== i18n.language) {
			i18n.changeLanguage(formattedLang)
		}

		dateLocales[formattedLang]().then(() => {
			dayjs.locale(formattedLang)
		})
	}, [locale])

	return locale
}

export default useLocale
