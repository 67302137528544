import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'

const AuthorityCheck = (props) => {
	const { userPermissions = [], permissions = [], children } = props

	const permissionMatched = useAuthority(userPermissions, permissions)

	return permissionMatched ? children : <></>
}

AuthorityCheck.propTypes = {
	userPermissions: PropTypes.array,
	permissions: PropTypes.array,
}

export default AuthorityCheck
