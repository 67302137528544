import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

function useAuthority(userPermissions = [], permissions = [], emptyCheck = false) {
	const permissionMatched = useMemo(() => {
		return permissions.some((permission) => userPermissions.includes(permission))
	}, [permissions, userPermissions])

	if (isEmpty(permissions) || isEmpty(userPermissions) || typeof permissions === 'undefined') {
		return !emptyCheck
	}

	return permissionMatched
}

export default useAuthority
