export default function attendanceFakeApi(server, apiPrefix) {
	// employee
	server.get(`${apiPrefix}/attendance-employee`, (schema) => {
		return schema.db.attendanceData
	})

	server.post(`${apiPrefix}/attendance-employee`, (schema, { requestBody }) => {
		return schema.db.attendanceEmployeePunchData
	})

	server.get(`${apiPrefix}/attendance-employee/logs`, (schema, { queryParams }) => {
		const punch_start_date = queryParams.filter[punch_start_date]
		const punch_end_date = queryParams.filter[punch_end_date]
		return schema.db.attendanceEmployeePunchLog
	})

	// admin
	server.get(`${apiPrefix}/api/v1/attendance-admin`, (schema, { queryParams }) => {
		return schema.db.attendanceAdminData
	})

	server.get(`${apiPrefix}/api/v1/attendance-admin/${yearMonth}/${id}`, (schema, { queryParams }) => {
		return schema.db.attendanceAdminDetailData
	})

	server.get(`${apiPrefix}/api/v1/attendance-export`, (schema, { queryParams }) => {
		return null
	})
}
