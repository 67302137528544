import { createSlice } from '@reduxjs/toolkit'

export const needApproveSlice = createSlice({
	name: 'auth/needApprove',
	initialState: {
		data: null,
		count: 0,
	},
	reducers: {
		setNeedApprove: (state, action) => {
			state.data = action.payload
			const { leave_count, overtime_count, punch_count } = action.payload
			state.count = leave_count + overtime_count + punch_count
		},
	},
})

export const { setNeedApprove } = needApproveSlice.actions

export default needApproveSlice.reducer
