import { i18n_PREFIX, i18n_SUFFIX } from 'constants/route.constant'

function headerRender(t, header) {
	if (header && header.startsWith(i18n_PREFIX)) {
		let result = header.replace(i18n_PREFIX, '')
		let option = null

		if (result.includes(i18n_SUFFIX)) {
			const splitEle = result.split(i18n_SUFFIX)
			result = splitEle[0]
			option = JSON.parse(splitEle[1])
			// console.log({ result, option })
		}
		return t(result, option)
	}
	return header
}

export default headerRender
