import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
		token: '',
		signedIn: false,
		expiresIn: null,
	},
	reducers: {
		onSignInSuccess: (state, action) => {
			state.signedIn = true
			state.token = action.payload
		},
		onSignOutSuccess: (state) => {
			state.signedIn = false
			state.token = ''
			state.expiresIn = null
		},
		setToken: (state, action) => {
			state.token = action.payload
		},
		setExpiresIn: (state, action) => {
			state.expiresIn = action.payload
		},
	},
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setExpiresIn } = sessionSlice.actions

export default sessionSlice.reducer
