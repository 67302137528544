export const signInUserData = [
	{
		id: '0',
		name: 'SUPER_ADMIN',
		job_no: null,
		email: 'system@chih-he.dev',
		username: 'system',
		email_verified_at: null,
		created_at: null,
		updated_at: null,
		password: '123456',
		roles: ['system'],
		permissions: ['PunchCard[create]', 'PunchCard[read]', 'PunchCard[update]', 'PunchCard[delete]'],
	},
	{
		id: '1',
		name: 'ADMIN',
		job_no: null,
		email: 'service@chih-he.dev',
		username: 'service',
		email_verified_at: null,
		created_at: null,
		updated_at: null,
		password: '123456',
		roles: ['administrator'],
		permissions: ['PunchCard[create]', 'PunchCard[read]', 'PunchCard[update]', 'PunchCard[delete]'],
	},
	{
		id: '2',
		name: 'EMPLOYEE',
		job_no: null,
		email: 'employee@chih-he.dev',
		username: 'employee',
		email_verified_at: null,
		created_at: null,
		updated_at: null,
		password: '123456',
		roles: ['Employee'],
		permissions: ['PunchCard[read]', 'PunchCard[create]'],
	},
]

export const loginData = {
	data: {
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcHVuY2gtYXBpLmNoaWgtaGUuZGV2XC9hcGlcL3YxXC9sb2dpbiIsImlhdCI6MTY3NjI3MzY2NCwiZXhwIjoxNjc2Mjc1NDY0LCJuYmYiOjE2NzYyNzM2NjQsImp0aSI6ImZhOW5Jc3VlelpjWTBITXUiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.C2-uc78Yt7nzjzTwce0B4XZnNqz9Tmi0rIFrxnVak-E',
		token_type: 'bearer',
		expires_in: 1800,
		settings: { timezone: 'Asia/Taipei', date: '2023-02-13T15:34:24+08:00' },
		profile: {
			id: 1,
			username: 'system',
			email: 'system@chih-he.dev',
			last_name: '\u8d85\u7d1a',
			first_name: '\u7ba1\u7406\u54e1',
			address: null,
			city: null,
			country: null,
			zip_code: null,
			company_id: 0,
			staff_photo: null,
			date_of_birth: null,
			the_highest_degree_of_education: null,
			national_area: null,
			passport_no: null,
			id_number: null,
			id_card_photo_positive: null,
			id_card_photo_back: null,
			native_place: null,
			blood_type: null,
			email_verified_at: null,
			created_at: '2023-01-04T17:25:59.000000Z',
			updated_at: '2023-02-08T06:41:32.000000Z',
			roles: ['Super Admin'],
			permissions: [],
		},
	},
}
