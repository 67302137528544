import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ScrollPanArea from 'components/shared/ScrollPanArea'

const Table = React.forwardRef((props, ref) => {
	const { borderlessRow, children, className, tableClass, hoverable, compact, asElement: Component, ...rest } = props

	const classList = classNames(className)

	const tableClassList = classNames(
		Component === 'table' ? 'table-default' : 'table-flex',
		hoverable && 'table-hover',
		compact && 'table-compact',
		borderlessRow && 'borderless-row',
		tableClass,
	)

	return (
		<ScrollPanArea className={classList}>
			<Component className={tableClassList} {...rest} ref={ref}>
				{children}
			</Component>
		</ScrollPanArea>
	)
})

Table.propTypes = {
	hoverable: PropTypes.bool,
	compact: PropTypes.bool,
	asElement: PropTypes.string,
	borderlessRow: PropTypes.bool,
}

Table.defaultProps = {
	hoverable: true,
	compact: false,
	asElement: 'table',
	borderlessRow: false,
}

export default Table
