import { TOKEN_TYPE } from 'constants/api.constant'
import ApiService from './ApiService'

export async function apiSignIn(data) {
	return ApiService.fetchData({
		url: '/login',
		method: 'post',
		data,
	})
}

export async function apiSignUp(data) {
	return ApiService.fetchData({
		url: '/sign-up',
		method: 'post',
		data,
	})
}

export async function apiSignOut(token) {
	return ApiService.fetchData({
		url: '/logout',
		method: 'post',
		headers: {
			Authorization: `${TOKEN_TYPE} ${token}`,
		},
	})
}

export async function apiProfile() {
	return ApiService.fetchData({
		url: '/me',
		method: 'get',
	})
}

export async function apiForgotPassword(data) {
	return ApiService.fetchData({
		url: '/forgot-password',
		method: 'post',
		data,
	})
}

export async function apiResetPassword(data) {
	return ApiService.fetchData({
		url: '/reset-password',
		method: 'post',
		data,
	})
}
export async function apiChangePassword(data) {
	return ApiService.fetchData({
		url: '/change-password',
		method: 'post',
		data,
	})
}
