import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

export const settingsSlice = createSlice({
	name: 'auth/settings',
	initialState: {
		date: null,
		timezone: null,
		currentDate: null,
		currentCompany: null,
		companies: [],
		fileCategories: [],
	},
	reducers: {
		setSettings: (state, action) => {
			state.date = action.payload.date
			state.currentDate = action.payload.date
			state.timezone = action.payload.timezone
		},
		setCurrentDate: (state, action) => {
			state.currentDate = action.payload
		},
		setCurrentCompany: (state, action) => {
			state.currentCompany = action.payload
		},
		setCompanies: (state, action) => {
			if (isEmpty(action.payload)) return

			const result = []

			action.payload.map((company) => {
				const companyData = Object.assign({}, company)
				companyData.value = company.id
				companyData.label = company.name

				if (!isEmpty(company.shift_times)) {
					companyData.shift_times = company.shift_times.map((shift_time) => {
						const shiftTimeData = Object.assign({}, shift_time)
						shiftTimeData.value = shift_time.id
						shiftTimeData.label = shift_time.name
						return shiftTimeData
					})
				}

				if (!isEmpty(company.workplaces)) {
					companyData.workplaces = company.workplaces.map((workplace) => {
						const workplaceData = Object.assign({}, workplace)
						workplaceData.value = workplace.id
						workplaceData.label = workplace.name
						return workplaceData
					})
				}

				if (!isEmpty(company.events)) {
					companyData.events = company.events.map((event) => {
						const eventData = Object.assign({}, event)
						eventData.value = event.id
						eventData.label = event.name
						return eventData
					})
				}

				return result.push(companyData)
			})
			state.currentCompany = result[0]
			state.companies = result
		},
		setFileCategories: (state, action) => {
			if (isEmpty(action.payload)) return

			const result = []

			action.payload.map((item) => {
				const categoryData = Object.assign({}, item)
				categoryData.value = item.id
				categoryData.label = item.name

				result.push(categoryData)
			})

			// const result = []

			// action.payload.map((company) => {
			// 	const companyData = Object.assign({}, company)
			// 	companyData.value = company.id
			// 	companyData.label = company.name

			// 	if (!isEmpty(company.shift_times)) {
			// 		companyData.shift_times = company.shift_times.map((shift_time) => {
			// 			const shiftTimeData = Object.assign({}, shift_time)
			// 			shiftTimeData.value = shift_time.id
			// 			shiftTimeData.label = shift_time.name
			// 			return shiftTimeData
			// 		})
			// 	}

			// 	return result.push(companyData)
			// })

			state.fileCategories = result
		},
	},
})

export const { setSettings, setCurrentDate, setCurrentCompany, setCompanies, setFileCategories } = settingsSlice.actions

export default settingsSlice.reducer
