import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_LANGUAGE_KEY } from 'constants/api.constant'
import { Notification, toast } from 'components/ui'
import { APP_ENVIRONMENT, APP_ENVIRONMENT_DEV, PERSIST_STORE_NAME, STORE_LANG } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { t } from 'i18next'
import isDev from 'utils/isDev'

export const unauthorizedCode = [401]
export const restrictedAccessCode = [403]

const BaseService = axios.create({
	timeout: 60000,
	baseURL: appConfig.enableMock ? appConfig.mockapiPrefix : appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
	(config) => {
		const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
		const persistData = deepParseJson(rawPersistData)
		const acceptLanguage = localStorage.getItem(STORE_LANG)

		const accessToken = persistData.auth.session.token

		// config.headers['Accept'] = '*/*'

		if (acceptLanguage) {
			config.headers[REQUEST_HEADER_LANGUAGE_KEY] = acceptLanguage
		}

		if (accessToken) {
			config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

BaseService.interceptors.response.use(
	(response) => response,
	(error) => {
		const { response } = error

		if (isDev()) {
			console.log('error response', response)
		}

		if (response && unauthorizedCode.includes(response.status)) {
			store.dispatch(onSignOutSuccess())
		}

		if (response && restrictedAccessCode.includes(response.status)) {
			let title = t('error.operation_failure_title')
			let msg = t('error.operation_failure')
			title = t('error.restricted_title')
			msg = t('error.restricted')

			if (response.config.method === 'post' && response.config.url === '/attendance-employee') {
				let ipRex = /\d{2,4}.\d{2,4}.\d{2,4}.\d{2,4}/
				let ip = response.data.message.match(ipRex)?.[0]
				return Promise.reject(ip)
			}

			toast.push(
				<Notification title={title} type="danger" closable>
					{msg}
				</Notification>,
			)
		}

		return Promise.reject(error)
	},
)

export default BaseService
