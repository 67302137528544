import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import zhTw from './lang/zh-tw.json'
import { themeConfig } from 'configs/theme.config'

const resources = {
	en: {
		translation: en,
	},
	zh_TW: {
		translation: zhTw,
	},
}

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: themeConfig.locale,
	lng: themeConfig.locale,
	interpolation: {
		escapeValue: false,
	},
})

export const dateLocales = {
	en: () => import('dayjs/locale/en'),
	zh_TW: () => import('dayjs/locale/zh-tw'),
}

export default i18n
