export const attendanceEmployeeData = {
	data: {
		last_today_punch_time: '2023-02-07 08:15:26',
		today_punch_logs: [
			{
				type: 'in',
				time: '2023-02-08 11:34:26',
			},
			{
				type: 'out',
				time: '2023-02-08 11:34:41',
			},
			{
				type: 'in',
				time: '2023-02-08 11:34:58',
			},
			{
				type: 'out',
				time: '2023-02-08 11:50:42',
			},
			{
				type: 'in',
				time: '2023-02-08 11:50:57',
			},
			{
				type: 'out',
				time: '2023-02-08 11:51:08',
			},
			{
				type: 'in',
				time: '2023-02-08 13:55:24',
			},
		],
		statistics: {
			today: 970,
			week: 15633,
			month: 15633,
		},
	},
}

export const attendanceEmployeePunchData = {
	message: 'User successfully punch.',
}

export const attendanceEmployeePunchLogs = {
	current_page: 1,
	data: [
		{
			id: 19,
			user_id: 2,
			company_id: 0,
			adt_in_time: '2023-02-15 19:46:48',
			adt_in_place: null,
			adt_in_hourse: null,
			adt_in_coordinate: null,
			adt_out_time: '2023-02-15 19:46:58',
			adt_out_place: null,
			adt_out_hourse: null,
			adt_out_coordinate: null,
			created_at: '2023-02-15T11:46:48.000000Z',
			updated_at: '2023-02-15T11:46:58.000000Z',
		},
		{
			id: 18,
			user_id: 2,
			company_id: 0,
			adt_in_time: '2023-02-15 17:36:40',
			adt_in_place: null,
			adt_in_hourse: null,
			adt_in_coordinate: null,
			adt_out_time: '2023-02-15 17:37:19',
			adt_out_place: null,
			adt_out_hourse: null,
			adt_out_coordinate: null,
			created_at: '2023-02-15T09:36:40.000000Z',
			updated_at: '2023-02-15T09:37:19.000000Z',
		},
		{
			id: 17,
			user_id: 2,
			company_id: 0,
			adt_in_time: '2023-02-15 17:09:17',
			adt_in_place: null,
			adt_in_hourse: null,
			adt_in_coordinate: null,
			adt_out_time: '2023-02-15 17:09:20',
			adt_out_place: null,
			adt_out_hourse: null,
			adt_out_coordinate: null,
			created_at: '2023-02-15T09:09:17.000000Z',
			updated_at: '2023-02-15T09:09:20.000000Z',
		},
		{
			id: 16,
			user_id: 2,
			company_id: 0,
			adt_in_time: '2023-02-15 15:03:43',
			adt_in_place: null,
			adt_in_hourse: null,
			adt_in_coordinate: null,
			adt_out_time: '2023-02-15 15:03:46',
			adt_out_place: null,
			adt_out_hourse: null,
			adt_out_coordinate: null,
			created_at: '2023-02-15T07:03:43.000000Z',
			updated_at: '2023-02-15T07:03:46.000000Z',
		},
		{
			id: 15,
			user_id: 2,
			company_id: 0,
			adt_in_time: '2023-02-15 15:02:59',
			adt_in_place: null,
			adt_in_hourse: null,
			adt_in_coordinate: null,
			adt_out_time: '2023-02-15 15:03:10',
			adt_out_place: null,
			adt_out_hourse: null,
			adt_out_coordinate: null,
			created_at: '2023-02-15T07:02:59.000000Z',
			updated_at: '2023-02-15T07:03:10.000000Z',
		},
	],
	first_page_url: 'https://punch-api.chih-he.dev/api/v1/attendance-employee/logs?page=1',
	from: 1,
	last_page: 1,
	last_page_url: 'https://punch-api.chih-he.dev/api/v1/attendance-employee/logs?page=1',
	links: [
		{ url: null, label: '&laquo; Previous', active: false },
		{ url: 'https://punch-api.chih-he.dev/api/v1/attendance-employee/logs?page=1', label: '1', active: true },
		{ url: null, label: 'Next &raquo;', active: false },
	],
	next_page_url: null,
	path: 'https://punch-api.chih-he.dev/api/v1/attendance-employee/logs',
	per_page: 15,
	prev_page_url: null,
	to: 5,
	total: 5,
	debugger: {
		server: {
			web_server: 'Apache/2.4.54 (Ubuntu) mod_fcgid/2.3.9 OpenSSL/3.0.2',
			protocol: 'HTTP/1.0',
			remote_address: '125.228.85.198',
			remote_port: '35246',
			server_name: 'punch-api.chih-he.dev',
			server_port: '443',
		},
		app: { environment: 'local', laravel_version: '8.83.27', php_version: '8.0.27', locale: 'zh_TW' },
		request: {
			ip: '125.228.85.198',
			uri: '/api/v1/attendance-employee/logs',
			method: 'GET',
			body: [],
			headers: {
				'cf-connecting-ip': ['125.228.85.198'],
				priority: ['u=1'],
				'if-none-match': ['W/"785eb108809a79a9ff3fa3e6ffb888be80b6c19c"'],
				referer: ['http://localhost:3000/'],
				'sec-fetch-dest': ['empty'],
				'sec-fetch-mode': ['cors'],
				'sec-fetch-site': ['cross-site'],
				origin: ['http://localhost:3000'],
				'sec-ch-ua-platform': ['"Windows"'],
				'user-agent': [
					'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36',
				],
				'sec-ch-ua-mobile': ['?0'],
				'accept-language': ['zh_TW'],
				accept: ['application/json, text/plain, */*'],
				'sec-ch-ua': ['"Not_A Brand";v="99", "Google Chrome";v="109", "Chromium";v="109"'],
				'cf-visitor': ['{"scheme":"https"}'],
				'x-forwarded-proto': ['https'],
				'cf-ray': ['79ce80fe1dbe1a0d-KIX'],
				'accept-encoding': ['gzip'],
				'cdn-loop': ['cloudflare'],
				'cf-ipcountry': ['TW'],
				connection: ['close'],
				'x-forwarded-for': ['125.228.85.198, 125.228.85.198'],
				host: ['punch-api.chih-he.dev'],
				authorization: [
					'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcHVuY2gtYXBpLmNoaWgtaGUuZGV2XC9hcGlcL3YxXC9sb2dpbiIsImlhdCI6MTY3Njk2OTc4NCwiZXhwIjoxNjc2OTcxNTg0LCJuYmYiOjE2NzY5Njk3ODQsImp0aSI6IndtY0lUa0VYYlZNS1NiemwiLCJzdWIiOjIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.AawNK21NUqvMFuocUIIgRI7JFhuRYRxassUdPA8fIgI',
				],
			},
		},
		session: {
			authenticated: true,
			token:
				'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcHVuY2gtYXBpLmNoaWgtaGUuZGV2XC9hcGlcL3YxXC9sb2dpbiIsImlhdCI6MTY3Njk2OTc4NCwiZXhwIjoxNjc2OTcxNTg0LCJuYmYiOjE2NzY5Njk3ODQsImp0aSI6IndtY0lUa0VYYlZNS1NiemwiLCJzdWIiOjIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.AawNK21NUqvMFuocUIIgRI7JFhuRYRxassUdPA8fIgI',
		},
		queries: {
			count: 3,
			data: [
				{ query: 'select * from `users` where `id` = ? limit 1', bindings: [2], time: 1.73 },
				{ query: 'select count(*) as aggregate from `attendance` where `user_id` = ?', bindings: [2], time: 0.31 },
				{
					query: 'select * from `attendance` where `user_id` = ? order by `adt_in_time` desc limit 15 offset 0',
					bindings: [2],
					time: 0.36,
				},
			],
		},
	},
}

export const attendanceAdminData = {
	data: [
		{
			type: 'attendance_admin',
			id: '3',
			attributes: {
				username: 'employee-01',
				last_name: null,
				first_name: null,
				month_in_day: ['13', '14', '15', '17', '20', '21', '22', '7', '8'],
			},
		},
		{
			type: 'attendance_admin',
			id: '4',
			attributes: {
				username: 'employee-02',
				last_name: null,
				first_name: null,
				month_in_day: ['15'],
			},
		},
		{
			type: 'attendance_admin',
			id: '5',
			attributes: {
				username: 'employee-03',
				last_name: null,
				first_name: null,
				month_in_day: [],
			},
		},
		{
			type: 'attendance_admin',
			id: '6',
			attributes: {
				username: 'employee-04',
				last_name: null,
				first_name: null,
				month_in_day: [],
			},
		},
		{
			type: 'attendance_admin',
			id: '7',
			attributes: {
				username: 'employee-05',
				last_name: null,
				first_name: null,
				month_in_day: [],
			},
		},
		{
			type: 'attendance_admin',
			id: '8',
			attributes: {
				username: 'employee-06',
				last_name: null,
				first_name: null,
				month_in_day: [],
			},
		},
	],
	meta: {
		pagination: {
			total: 6,
			count: 6,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		},
	},
	links: {
		self: 'https://punch-api.chih-he.dev/api/v1/attendance-admin?page=1',
		first: 'https://punch-api.chih-he.dev/api/v1/attendance-admin?page=1',
		last: 'https://punch-api.chih-he.dev/api/v1/attendance-admin?page=1',
	},
}

export const attendanceAdminDetailData = {
	data: {
		punch_in_at: '2023-02-13T09:15:47+08:00',
		punch_out_at: '2023-02-13T11:42:31+08:00',
		punch_logs: [
			{
				type: 'in',
				time: '2023-02-13T09:15:47+08:00',
			},
			{
				type: 'out',
				time: '2023-02-13T11:42:31+08:00',
			},
		],
		statistics: {
			work: 8804,
			break: 0,
			overtime: 0,
		},
	},
}
