import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'

import { signInUserData } from './data/authData'
import { usersData } from './data/usersData'

import {
	attendanceEmployeeData,
	attendanceEmployeePunchData,
	attendanceEmployeePunchLogs,
	attendanceAdminData,
	attendanceAdminDetailData,
} from './data/attendanceData'

import {
	settingData,
	settingIntergrationData,
	settingBillingData,
	invoiceData,
	logData,
	accountFormData,
} from './data/accountData'

import { shiftsAdminData } from './data/shiftsData'

import { accountFakeApi, authFakeApi, shiftsFakeApi, usersFakeApi, attendanceFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
	return createServer({
		environment,
		seeds(server) {
			server.db.loadData({
				signInUserData,
				settingData,
				settingIntergrationData,
				settingBillingData,
				invoiceData,
				logData,
				shiftsAdminData,
				accountFormData,
				usersData,
				attendanceEmployeeData,
				attendanceEmployeePunchData,
				attendanceEmployeePunchLogs,
				attendanceAdminData,
				attendanceAdminDetailData,
			})
		},
		routes() {
			this.urlPrefix = ''
			this.namespace = ''
			this.passthrough((request) => {
				let isExternal = request.url.startsWith('http')
				return isExternal
			})
			this.passthrough()

			authFakeApi(this, apiPrefix)
			accountFakeApi(this, apiPrefix)
			usersFakeApi(this, apiPrefix)
			attendanceFakeApi(this, apiPrefix)
			shiftsFakeApi(this, apiPrefix)
		},
	})
}
