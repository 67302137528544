export const usersData = {
	data: [
		{
			type: 'user',
			id: '1',
			attributes: {
				last_name: '超級',
				first_name: '管理員',
				email: 'system@chih-he.dev',
				company_id: 0,
				created_at: '2023-01-04T17:25:59.000000Z',
				updated_at: '2023-02-08T06:41:32.000000Z',
			},
		},
		{
			type: 'user',
			id: '2',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'service@chih-he.dev',
				company_id: 0,
				created_at: '2023-01-16T11:22:22.000000Z',
				updated_at: '2023-02-01T03:51:13.000000Z',
			},
		},
		{
			type: 'user',
			id: '3',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'employee@chih-he.dev',
				company_id: 0,
				created_at: '2023-01-16T11:24:31.000000Z',
				updated_at: '2023-02-08T06:47:04.000000Z',
			},
		},
		{
			type: 'user',
			id: '4',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'do.rubbish2@gmail.com',
				company_id: 0,
				created_at: '2023-01-16T11:24:54.000000Z',
				updated_at: '2023-01-16T11:24:54.000000Z',
			},
		},
		{
			type: 'user',
			id: '5',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'do.rubbish4@gmail.com',
				company_id: 0,
				created_at: '2023-01-16T12:48:40.000000Z',
				updated_at: '2023-01-16T12:48:40.000000Z',
			},
		},
		{
			type: 'user',
			id: '6',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'do.rubbish6@gmail.com',
				company_id: 0,
				created_at: '2023-01-16T12:50:26.000000Z',
				updated_at: '2023-01-16T12:50:26.000000Z',
			},
		},
		{
			type: 'user',
			id: '7',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'do.rubbish7@gmail.com',
				company_id: 0,
				created_at: '2023-01-16T12:53:17.000000Z',
				updated_at: '2023-01-16T12:53:17.000000Z',
			},
		},
		{
			type: 'user',
			id: '8',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'do.rubbish10@gmail.com',
				company_id: 0,
				created_at: '2023-01-16T12:53:27.000000Z',
				updated_at: '2023-01-16T12:53:27.000000Z',
			},
		},
		{
			type: 'user',
			id: '9',
			attributes: {
				last_name: null,
				first_name: null,
				email: 'example-01@chih-he.dev',
				company_id: 0,
				created_at: '2023-02-07T09:14:45.000000Z',
				updated_at: '2023-02-07T09:14:45.000000Z',
			},
		},
		{
			type: 'user',
			id: '10',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-02@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:01:59.000000Z',
				updated_at: '2023-02-08T07:01:59.000000Z',
			},
		},
		{
			type: 'user',
			id: '11',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-03@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:02:06.000000Z',
				updated_at: '2023-02-08T07:02:06.000000Z',
			},
		},
		{
			type: 'user',
			id: '12',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-04@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:02:58.000000Z',
				updated_at: '2023-02-08T07:02:58.000000Z',
			},
		},
		{
			type: 'user',
			id: '13',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-05@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:03:10.000000Z',
				updated_at: '2023-02-08T07:03:10.000000Z',
			},
		},
		{
			type: 'user',
			id: '14',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-06@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:03:34.000000Z',
				updated_at: '2023-02-08T07:03:34.000000Z',
			},
		},
		{
			type: 'user',
			id: '15',
			attributes: {
				last_name: 'Lastname',
				first_name: 'Firstname',
				email: 'example-07@chih-he.dev',
				company_id: 1,
				created_at: '2023-02-08T07:03:40.000000Z',
				updated_at: '2023-02-08T07:03:40.000000Z',
			},
		},
	],
	meta: {
		pagination: {
			total: 16,
			count: 15,
			per_page: 15,
			current_page: 1,
			total_pages: 2,
		},
	},
	links: {
		self: 'https://punch-api.chih-he.dev/api/v1/users?page=1',
		first: 'https://punch-api.chih-he.dev/api/v1/users?page=1',
		next: 'https://punch-api.chih-he.dev/api/v1/users?page=2',
		last: 'https://punch-api.chih-he.dev/api/v1/users?page=2',
	},
}
