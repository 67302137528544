const appConfig = {
	mockapiPrefix: '/api',
	apiPrefix: process.env.REACT_APP_API_URL + '/api/v1',
	imagePrefix: process.env.REACT_APP_API_URL + '/data/',
	authenticatedEntryPath: '/home',
	unAuthenticatedEntryPath: '/login',
	tourPath: '/',
	enableMock: false,
}

export default appConfig
