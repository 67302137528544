import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

const ScrollPanArea = (props) => {
	const { className, children, ...rest } = props
	const scrollRef = useRef()
	const arrowLeftRef = useRef()
	const arrowRightRef = useRef()
	const [arrowLeftShow, setArrowLeftShow] = useState(false)
	const [arrowRightShow, setArrowRightShow] = useState(false)

	let panAllow = true
	let dragging = false

	const parentClassNameList = classNames('relative overflow-hidden max-w-full')

	const classNameList = classNames(className, 'scroll-smooth overflow-y-auto')

	const arrowClassName = (position) =>
		classNames(
			'cursor-pointer bg-black/5 absolute top-0 bottom-0 z-[11] flex items-center justify-center',
			position === 'left' && 'left-0',
			position === 'right' && 'right-0',
			position === 'left' && !arrowLeftShow ? 'hidden' : '',
			position === 'right' && !arrowRightShow ? 'hidden' : '',
		)

	const displayIcons = () => {
		const arrorWidth = arrowRightRef?.current?.offsetWidth

		if (
			scrollRef?.current?.scrollWidth !== scrollRef?.current?.clientWidth &&
			scrollRef?.current?.scrollLeft >= arrorWidth
		) {
			setArrowLeftShow(true)
		} else {
			setArrowLeftShow(false)
		}

		let maxScrollValue = scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth - arrorWidth

		if (Math.round(scrollRef?.current?.scrollLeft) >= maxScrollValue) {
			setArrowRightShow(false)
		} else {
			setArrowRightShow(true)
		}
	}

	const onClickArrorButton = (position) => {
		if (position === 'left') {
			scrollRef.current.scrollLeft -= 200
		} else {
			scrollRef.current.scrollLeft += 200
		}
	}

	const handleOnMouseMove = (e) => {
		if (dragging) {
			scrollRef.current.scrollLeft -= e.movementX
			scrollRef.current.classList.add('cursor-move')
			scrollRef.current.classList.add('select-none')
			scrollRef.current.classList.remove('scroll-smooth')
		} else {
			scrollRef.current.classList.remove('cursor-move')
			scrollRef.current.classList.remove('select-none')
			scrollRef.current.classList.add('scroll-smooth')
		}
	}

	const handleOnMouseDown = () => {
		if (panAllow) dragging = true
	}

	const mouseUp = () => {
		dragging = false
	}

	const handleOnKeyDown = (e) => {
		if (e.key === 'Shift') {
			panAllow = false
		}
	}

	const handleOnKeyUp = (e) => {
		panAllow = true
	}

	useEffect(() => {
		if (isEmpty(scrollRef) || isEmpty(arrowLeftRef) || isEmpty(arrowRightRef) || isEmpty(children)) return
		displayIcons()
	}, [scrollRef, arrowLeftRef, arrowRightRef, children])

	useEffect(() => {
		if (isEmpty(scrollRef)) return

		window.addEventListener('keydown', handleOnKeyDown)
		window.addEventListener('keyup', handleOnKeyUp)
		document.addEventListener('mouseup', mouseUp)
		scrollRef?.current?.addEventListener('scroll', displayIcons)
		scrollRef?.current?.addEventListener('mousemove', handleOnMouseMove)
		scrollRef?.current?.addEventListener('mousedown', handleOnMouseDown)

		return () => {
			window.removeEventListener('keydown', handleOnKeyDown)
			window.removeEventListener('keyup', handleOnKeyUp)
			document.removeEventListener('mouseup', mouseUp)
			scrollRef?.current?.removeEventListener('scroll', displayIcons)
			scrollRef?.current?.removeEventListener('mousemove', handleOnMouseMove)
			scrollRef?.current?.removeEventListener('mousedown', handleOnMouseDown)
		}
	}, [scrollRef])

	return (
		<div className={parentClassNameList}>
			<div ref={scrollRef} className={classNameList} {...rest}>
				<div ref={arrowLeftRef} className={arrowClassName('left')} onClick={() => onClickArrorButton('left')}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="w-5 h-5"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
					</svg>
				</div>
				{children}
				<div ref={arrowRightRef} className={arrowClassName('right')} onClick={() => onClickArrorButton('right')}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="w-5 h-5"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
					</svg>
				</div>
			</div>
		</div>
	)
}

export default ScrollPanArea
