import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AiOutlineExpand } from 'react-icons/ai'

import { Button, Dialog } from 'components/ui'

const Embed = React.forwardRef((props, ref) => {
	const { title, enlarge, width, height, children, className, innerClassName, ...rest } = props
	const { t } = useTranslation()
	const radio = height / width || 9 / 16
	const [dialogIsOpen, setIsOpen] = useState(false)

	const childrenClassName = '[&>*]:absolute [&>*]:top-0 [&>*]:left-0 [&>*]:w-full [&>*]:h-full'

	const embedClassName = classNames('h-0 overflow-hidden relative', childrenClassName, innerClassName)

	const outerClassName = classNames(className)

	const openDialog = () => {
		setIsOpen(true)
	}

	const onDialogClose = (e) => {
		setIsOpen(false)
	}

	return (
		<div className={outerClassName}>
			<div className={embedClassName} style={{ paddingBottom: `calc(99.99% * ${radio})` }} {...rest}>
				{children}
			</div>
			{enlarge && (
				<div className="text-center mt-2">
					<Button
						className="px-6"
						size="sm"
						icon={<AiOutlineExpand />}
						shape="circle"
						variant="twoTone"
						iconPosition="end"
						onClick={() => openDialog()}
					>
						{t('common.interect.enlarge')}
					</Button>
					<Dialog isOpen={dialogIsOpen} onClose={onDialogClose} onRequestClose={onDialogClose} width={900}>
						<div className="flex flex-col h-full justify-between">
							<h5 className="mb-4">{title}</h5>
							<div className={embedClassName} style={{ paddingBottom: `calc(99.99% * ${radio})` }} {...rest}>
								{children}
							</div>
						</div>
					</Dialog>
				</div>
			)}
		</div>
	)
})

Embed.propTypes = {
	className: PropTypes.string,
	enlarge: PropTypes.bool,
	height: PropTypes.number,
	innerClassName: PropTypes.string,
	title: PropTypes.string,
	width: PropTypes.number,
}

Embed.defaultProps = {
	enlarge: false,
	title: '',
}

export default Embed
