import dayjs from 'dayjs'

function dateList(name) {
	const now = new Date()
	const start = new Date(now.getFullYear(), now.getMonth() - 1, 18)
	const end = new Date(now.getFullYear(), now.getMonth() + 1, 14)
	let dayOfMonth = [start]
	let schedule = []

	let loop = new Date(start)

	while (loop < end) {
		dayOfMonth.push(loop)
		let newDate = loop.setDate(loop.getDate() + 1)
		loop = new Date(newDate)
	}

	dayOfMonth.map((item, index) => {
		schedule.push({
			date: dayjs(item).format('YYYYMMDD'),
			name: name,
			start_time: name ? '09:00:00' : '',
			end_time: name ? '18:00:00' : '',
			remarks: 'testestest',
			events: name ? [1] : [],
			workplaces: name ? [1] : [],
			method: name && index % 4 === 0 ? 2 : 1,
			error:
				name && index % 4 === 0
					? {
							msg: '有問題',
					  }
					: null,
		})
	})

	return schedule
}

export const shiftsAdminData = {
	data: [
		{
			user_id: 1,
			user_zh_full_name: '温惠君',
			user_en_full_name: '',
			user_username: 'macywen',
			company_id: 1,
			time_of_entry: null,
			create_month: '202304',
			schedules: dateList(''),
		},
		{
			user_id: 2,
			user_zh_full_name: '鄒承運',
			user_en_full_name: '',
			user_username: 'chengyun.eftc',
			company_id: 1,
			time_of_entry: null,
			create_month: '202304',
			schedules: dateList('B'),
		},
		{
			user_id: 3,
			user_zh_full_name: '梁語真',
			user_en_full_name: '',
			user_username: 'yuchenliang',
			company_id: 1,
			time_of_entry: null,
			create_month: '202304',
			schedules: dateList('C'),
		},
	],
}

export const shiftAdminSendData = [
	{
		user_id: 1,
		date: '20230401',
		name: 'A',
		start_time: '',
		end_time: '',
		punch_start_time: '',
		punch_end_time: '',
		workplaces: [1],
		remarks: '',
	},
	{
		user_id: 1,
		date: '20230401',
		name: 'A',
		start_time: '',
		end_time: '',
		punch_start_time: '',
		punch_end_time: '',
		workplaces: [1],
		remarks: '',
	},
]

export const shiftsEmployeeData = {
	data: [
		{
			user_id: 3,
			user_zh_full_name: '梁語真',
			user_en_full_name: '',
			schedule_date: '2023-05-01',
			schedule_name: 'A',
			schedule_start_time: '2023-05-01T09:00:00',
			schedule_end_time: '2023-05-01T18:00:00',
			remarks: '',
		},
		{
			user_id: 3,
			user_zh_full_name: '梁語真',
			user_en_full_name: '',
			schedule_date: '2023-05-02',
			schedule_name: 'A',
			schedule_start_time: '2023-05-01T09:00:00',
			schedule_end_time: '2023-05-01T18:00:00',
			remarks: '',
		},
	],
	meta: {
		festivals: [
			{
				date: '2023-05-01',
				items: [],
			},
			{
				date: '2023-05-02',
				items: [],
			},
			{
				date: '2023-05-03',
				items: [],
			},
			{
				date: '2023-05-04',
				items: [],
			},
			{
				date: '2023-05-05',
				items: [],
			},
			{
				date: '2023-05-06',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-07',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-08',
				items: [],
			},
			{
				date: '2023-05-09',
				items: [],
			},
			{
				date: '2023-05-10',
				items: [],
			},
			{
				date: '2023-05-11',
				items: [],
			},
			{
				date: '2023-05-12',
				items: [],
			},
			{
				date: '2023-05-13',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-14',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-15',
				items: [],
			},
			{
				date: '2023-05-16',
				items: [],
			},
			{
				date: '2023-05-17',
				items: [],
			},
			{
				date: '2023-05-18',
				items: [],
			},
			{
				date: '2023-05-19',
				items: [],
			},
			{
				date: '2023-05-20',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-21',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-22',
				items: [],
			},
			{
				date: '2023-05-23',
				items: [],
			},
			{
				date: '2023-05-24',
				items: [],
			},
			{
				date: '2023-05-25',
				items: [],
			},
			{
				date: '2023-05-26',
				items: [],
			},
			{
				date: '2023-05-27',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-28',
				items: [
					{
						name: '假日',
						type: 1,
						color: '#D81860',
					},
				],
			},
			{
				date: '2023-05-29',
				items: [],
			},
			{
				date: '2023-05-30',
				items: [],
			},
			{
				date: '2023-05-31',
				items: [],
			},
		],
	},
}
