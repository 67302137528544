import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useQuery from './useQuery'
import appConfig from 'configs/app.config'
import { PERSIST_STORE_NAME, REDIRECT_URL_KEY } from 'constants/app.constant'

import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess, setExpiresIn } from 'store/auth/sessionSlice'
import { setCompanies, setFileCategories, setSettings } from 'store/auth/settingsSlice'
import { setNeedApprove } from 'store/auth/needApproveSlice'

import { apiChangePassword, apiProfile, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'

import { toast, Notification } from 'components/ui'

import deepParseJson from 'utils/deepParseJson'

function useAuth() {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const signIn = async (values) => {
		try {
			const {
				data: { data },
			} = await apiSignIn(values)

			if (data) {
				// TODO: 編輯 object 的方式優化放到 utils 裡面
				const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
				const persistData = deepParseJson(rawPersistData)
				const newData = Object.assign({}, persistData)

				newData.auth.session.token = data.token
				newData.auth.session.expiresIn = data.expires_in
				newData.auth.session.signedIn = true

				localStorage.setItem(PERSIST_STORE_NAME, JSON.stringify(newData))

				await dispatch(onSignInSuccess(data.token))
				await dispatch(setExpiresIn(data.expires_in))
				await dispatch(setSettings(data.settings))
				await dispatch(setCompanies(data.companies))
				await dispatch(setFileCategories(data.file_categories))
				await dispatch(setNeedApprove(data.need_approve))

				if (data.profile) {
					await dispatch(setUser(data.profile || initialState))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: '',
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			}
		}
	}

	const signUp = async (values) => {
		try {
			const {
				data: { data },
			} = await apiSignUp(values)

			if (data) {
				const { token } = data
				dispatch(onSignInSuccess(token))
				if (data.user) {
					dispatch(setUser(data.user || initialState))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
				return {
					status: 'success',
					message: '',
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			}
		}
	}

	const profile = async () => {
		try {
			const {
				data: { data },
			} = await apiProfile()

			if (data) {
				if (data.profile) {
					await dispatch(setSettings(data.settings))
					await dispatch(setCompanies(data.companies))
					await dispatch(setUser(data.profile || initialState))
					await dispatch(setFileCategories(data.file_categories))
					await dispatch(setNeedApprove(data.need_approve))
				}

				return {
					status: 'success',
					message: '',
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			}
		}
	}

	const changePassword = async (values) => {
		try {
			const { data } = await apiChangePassword(values)

			if (data) {
				toast.push(<Notification title={data.message} type="success" />, {
					placement: 'top-center',
				})
				handleSignOut()
				navigate(appConfig.unAuthenticatedEntryPath)
				return {
					status: 'success',
					message: data.message,
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			}
		}
	}

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		await apiSignOut(token)
		handleSignOut()
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut,
		profile,
		changePassword,
	}
}

export default useAuth
