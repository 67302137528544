import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { GroupContextProvider } from './context/groupContext'
import MenuContext from './context/menuContext'
import useUniqueId from '../hooks/useUniqueId'

const MenuGroup = (props) => {
	const { label, children, className, titleClassName } = props

	const { variant, sideCollapsed } = useContext(MenuContext)

	const menuGroupDefaultClass = 'menu-group'
	const menuGroupClass = classNames(menuGroupDefaultClass, className)

	const menuTitleClass = classNames('menu-title', `menu-title-${variant}`, titleClassName)

	const entityHeaderId = useUniqueId('entity-header-')

	return (
		<div
			className={menuGroupClass.replace(
				sideCollapsed ? /px-(\d+|\[\d+(?:\.\d+)?(?:px|em|%|rem)]|\[\d+(?:\.\d+)?(?:px|em|%|rem)\])\s?/g : '',
				'',
			)}
		>
			{label && !sideCollapsed && (
				<div className={menuTitleClass} id={entityHeaderId}>
					{label}
				</div>
			)}
			<GroupContextProvider value>
				<ul>{children}</ul>
			</GroupContextProvider>
		</div>
	)
}

MenuGroup.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,
	className: PropTypes.string,
}

MenuGroup.defaultProps = {
	label: null,
}

export default MenuGroup
