import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import settings from './settingsSlice'
import needApprove from './needApproveSlice'

const reducer = combineReducers({
	session,
	user,
	settings,
	needApprove,
})

export default reducer
