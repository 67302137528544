import { Response } from 'miragejs'
import uniqueId from 'lodash/uniqueId'
import isEmpty from 'lodash/isEmpty'

export default function authFakeApi(server, apiPrefix) {
	server.post(`${apiPrefix}/login`, (schema, { requestBody }) => {
		const { username, password } = JSON.parse(requestBody)
		const user = schema.db.signInUserData.findBy({ username, password })

		console.log('user', user)

		if (user) {
			const { avatar, username, email, authority } = user
			return {
				user: { avatar, username, email, authority },
				token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
			}
		}
		return new Response(401, { some: 'header' }, { message: 'Invalid email or password!' })
	})

	server.post(`${apiPrefix}/logout`, () => {
		return true
	})

	server.post(`${apiPrefix}/sign-up`, (schema, { requestBody }) => {
		const { username, password, email } = JSON.parse(requestBody)
		const userExist = schema.db.signInUserData.findBy({ username: username })
		const emailUsed = schema.db.signInUserData.findBy({ email })
		const newUser = {
			avatar: '/img/avatars/thumb-1.jpg',
			username,
			email,
			permissions: ['PunchCard[read]', 'PunchCard[create]'],
		}
		if (!isEmpty(userExist)) {
			const errors = [{ message: '', domain: 'global', reason: 'invalid' }]
			return new Response(400, { some: 'header' }, { errors, message: 'User already exist!' })
		}

		if (!isEmpty(emailUsed)) {
			const errors = [{ message: '', domain: 'global', reason: 'invalid' }]
			return new Response(400, { some: 'header' }, { errors, message: 'Email already used' })
		}

		schema.db.signInUserData.insert({
			...newUser,
			...{ id: uniqueId('user_'), password, username: username },
		})
		return {
			user: newUser,
			token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
		}
	})

	server.post(`${apiPrefix}/reset-password`, () => {
		return true
	})

	server.post(`${apiPrefix}/forgot-password`, () => {
		return true
	})

	server.post(`${apiPrefix}/change-password`, () => {
		return true
	})
}
