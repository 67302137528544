import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
	id: null,
	username: null,
	email: null,
	zh_full_name: null,
	en_full_name: null,
	company_id: null,
	roles: [],
	permissions: [],
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (_, action) => action.payload,
		userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
