import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
	currentRouteTitle: '',
	currentRouteKey: '',
	loading: false,
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
		setCurrentRouteKey: (state, action) => {
			state.currentRouteKey = action.payload
		},
		setCurrentRouteTitle: (state, action) => {
			state.currentRouteTitle = action.payload
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
	},
})

export const { setCurrentRouteKey, setCurrentRouteTitle, setLoading } = commonSlice.actions

export default commonSlice.reducer
